<template>
  <div></div>
</template>
<script>
export default {
  name: 'pc',
  mounted() {
    const backUrl = { // 本地, 默认, 测试, 预发, 正式
      'local': 'http://dev.kaoyan-vip.cn:7001',
      'default': 'http://www.kaoyan-vip.cn',
      'test': 'http://www.kaoyan-vip.cn',
      'preview': 'http://www.kaoyan-vip.com',
      'prod': 'https://www.kaoyanvip.cn',
    }
    var params = window.location.href.split('?')[1]
    var env = this.$route.query.env
    Object.prototype.hasOwnProperty.call(backUrl, env)|| (env = 'default')
    window.location.href =  `${backUrl[env]}/callback_wechat_login?${params}`
    // window.location.href =  backUrl[env] + '/callback_wechat_login?' + params
  }
}
</script>
